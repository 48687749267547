<template>
  <div>
    <div class="title"><h3>لك سيدتي</h3></div>
    <v-divider></v-divider>

    <div class="container">
      <v-row
        ><v-col xs="12"
          ><img src="@/assets/Images/14.jpg" class="rotate-vert-center"
        /></v-col>
        <v-col xs="12"
          ><img src="@/assets/Images/13.jpg" class="rotate-vert-center"
        /></v-col>
        <v-col xs="12"
          ><img
            src="@/assets/Images/15.jpg"
            class="rotate-vert-center" /></v-col
      ></v-row>
      <v-row
        ><h3>
          الخطوات التي يجب اتباعها لتخفيض استهلاك الفاتورة والمحافظة على المياه
        </h3>
        <ul class="mb-10">
          <li>
            غلق الحنفية أثناء غسيل الأسنان بالفرشاة (إن معدل الوقت المستغرق
            لتنظيف الأسنان هو دقيقة وعشرين ثانية فإذا فتحنا حنفية الماء طوال تلك
            المدة فسنكون قد أهدرنا حوالي 8 لترات من الماء.)
          </li>
          <li>
            - تأكدي من غلق الأولاد الحنفية جيدا بعد الاستخدام مباشرة، سواء بعد
            غسيل الأيدي أو الاستحمام.
          </li>
          <li>
            - يمكنك وضع وعاء في الحوض عند غسيل الخضروات وإعادة استخدامها في ري
            النباتات أو ملء صندوق الصرف (السيفون) منها.
          </li>
          <li>
            - تأكدي من عدم تسريب أي من الأدوات الصحية للمياه بالمنزل وسارعي في
            صيانتها إن وجد التسريب.
          </li>
          <li>
            - يمكنك صيانة الأدوات الصحية الخفيفية بنفسك، من خلال الاسترشاد بدليل
            مبادىء «فنيات السباكة».
          </li>
          <li>
            - باستخدامك للقطع الموفرة في الأدوات الصحية يمكنك توفير 50% من
            الاستهلاك الشهري، فالقطع الموفرة تعمل على تخفيض تدفق المياه بنسبة
            35% من خلال شكة المصفاة الموجودة بالقطعة.
          </li>
          <li>
            - انتظامك في سداد فاتورتك يضمن لك استمرار توصيل الخدمة بالجودة
            المطلوبة.
          </li>
          <li>
            - تطهير خزانات المياه يضمن لكي ولأولادك مياه نظيفة وصحية لطلب تطهير
            خزانك برجاء الاتصال بالخط الساخن 125 .
          </li>
          <li>
            - في حال وجود شكاوى او استفسارات تتعلق بالمياه أو الصرف الصحي يمكنك
            الاتصال برقم 125 من أي تليفون ارضي
          </li>
        </ul>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
img{
  width:300px;
  height:200px
}
</style>